import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { navigate } from "gatsby"

// import AlgoliaBoxSearch from '../components/algolia/search'

import { login,isAuthenticated} from "../utils/auth"


const useStyles = makeStyles((theme
  ) => ({
  root: {
    display: 'flex'
  },
  paper: {
    display: 'flex',
    padding: theme.spacing(2),
    color: 'white',
    backgroundColor:'gray',
    height: '15vh',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    textShadow: '1px 1px 2px black',
  },
  paper1: {
    display: 'flex',
    padding: theme.spacing(2),
    color: 'white',
    backgroundColor:'gray',
    fontSize: '1.5rem',
    textShadow: '1px 1px 2px black',
    height: '30vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

export default function Recruter() {
  const classes = useStyles();


  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }else{
  return (
  <Container >
    <Box borderTop={1} borderLeft={1}  >
        <h1 style={{textAlign: 'center'}}>Editorial </h1>
        <p>....</p>
    </Box>
    <Box style={{marginTop: '10vh'}}>
      {/* <AlgoliaBoxSearch /> */}
    <Grid container spacing={2} 
    direction='row-reverse' 
    justify="center"
    alignItems="center">
        <Grid item xs={4}  >
          <Paper className={classes.paper1 } style={{backgroundColor:'#FFF6B5'}}
          onClick={()=>(navigate('/'))}>
           Part1
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper className={classes.paper1} style={{backgroundColor:'#5240A8'}}
          onClick={()=>(navigate('/'))}>
            Part2
          </Paper>
        </Grid>
        <Grid item xs= {6}>
          <Paper className={classes.paper} style={{backgroundColor:'#A58EF5'}}
          onClick={()=>(navigate('/'))}>
            Part3
          </Paper>
        </Grid> 
        <Grid item xs= {6}>
          <Paper className={classes.paper}  style={{backgroundColor:'#F5E98E'}}
          onClick={()=>(navigate('/'))}>
            Part4
          </Paper>
        </Grid> 
        <Grid item xs>
          <Paper className={classes.paper}   style={{backgroundColor:'#9E9547'}}
          onClick={()=>(navigate('/'))}>
            Part5
          </Paper>
        </Grid> 
    </Grid>
    </Box>
  </Container>
  );
  }
}